.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot');
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.woff') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.ttf') format('truetype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.svg?#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.rc-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  color: #666;
}
.rc-menu-hidden {
  display: none;
}
.rc-menu-collapse {
  overflow: hidden;
}
.rc-menu-collapse-active {
  transition: height 0.3s ease-out;
}
.rc-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}
.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: #eaf8fe;
}
.rc-menu-item-selected {
  background-color: #eaf8fe;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.rc-menu-submenu-selected {
  background-color: #eaf8fe;
}
.rc-menu > li.rc-menu-submenu {
  padding: 0;
}
.rc-menu-horizontal.rc-menu-sub,
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  min-width: 160px;
  margin-top: 0;
}
.rc-menu-item,
.rc-menu-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  padding: 7px 7px 7px 16px;
  white-space: nowrap;
}
.rc-menu-item.rc-menu-item-disabled,
.rc-menu-submenu-title.rc-menu-item-disabled,
.rc-menu-item.rc-menu-submenu-disabled,
.rc-menu-submenu-title.rc-menu-submenu-disabled {
  color: #777 !important;
}
.rc-menu > .rc-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #e5e5e5;
}
.rc-menu-submenu-popup {
  position: absolute;
}
.rc-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.rc-menu-submenu > .rc-menu {
  background-color: #fff;
}
.rc-menu .rc-menu-submenu-title .anticon,
.rc-menu .rc-menu-item .anticon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  top: -1px;
}
.rc-menu-horizontal {
  background-color: #F3F5F7;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
}
.rc-menu-horizontal > .rc-menu-item,
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 15px 20px;
}
.rc-menu-horizontal > .rc-menu-submenu,
.rc-menu-horizontal > .rc-menu-item {
  border-bottom: 2px solid transparent;
  display: inline-block;
  vertical-align: bottom;
}
.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  border-bottom: 2px solid #2db7f5;
  background-color: #F3F5F7;
  color: #2baee9;
}
.rc-menu-horizontal:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
.rc-menu-vertical,
.rc-menu-vertical-left,
.rc-menu-vertical-right,
.rc-menu-inline {
  padding: 12px 0;
}
.rc-menu-vertical > .rc-menu-item,
.rc-menu-vertical-left > .rc-menu-item,
.rc-menu-vertical-right > .rc-menu-item,
.rc-menu-inline > .rc-menu-item,
.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 12px 8px 12px 24px;
}
.rc-menu-vertical .rc-menu-submenu-arrow,
.rc-menu-vertical-left .rc-menu-submenu-arrow,
.rc-menu-vertical-right .rc-menu-submenu-arrow,
.rc-menu-inline .rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 16px;
  line-height: 1.5em;
}
.rc-menu-vertical .rc-menu-submenu-arrow:before,
.rc-menu-vertical-left .rc-menu-submenu-arrow:before,
.rc-menu-vertical-right .rc-menu-submenu-arrow:before,
.rc-menu-inline .rc-menu-submenu-arrow:before {
  content: "\F0DA";
}
.rc-menu-inline .rc-menu-submenu-arrow {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
.rc-menu-inline .rc-menu-submenu-open > .rc-menu-submenu-title .rc-menu-submenu-arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  padding: 0;
}
.rc-menu-sub.rc-menu-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 0;
}
.rc-menu-open-slide-up-enter,
.rc-menu-open-slide-up-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-menu-open-slide-up-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-menu-open-slide-up-enter.rc-menu-open-slide-up-enter-active,
.rc-menu-open-slide-up-appear.rc-menu-open-slide-up-appear-active {
  -webkit-animation-name: rcMenuOpenSlideUpIn;
          animation-name: rcMenuOpenSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-menu-open-slide-up-leave.rc-menu-open-slide-up-leave-active {
  -webkit-animation-name: rcMenuOpenSlideUpOut;
          animation-name: rcMenuOpenSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcMenuOpenSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes rcMenuOpenSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes rcMenuOpenSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes rcMenuOpenSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
.rc-menu-open-zoom-enter,
.rc-menu-open-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-menu-open-zoom-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-menu-open-zoom-enter.rc-menu-open-zoom-enter-active,
.rc-menu-open-zoom-appear.rc-menu-open-zoom-appear-active {
  -webkit-animation-name: rcMenuOpenZoomIn;
          animation-name: rcMenuOpenZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-menu-open-zoom-leave.rc-menu-open-zoom-leave-active {
  -webkit-animation-name: rcMenuOpenZoomOut;
          animation-name: rcMenuOpenZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcMenuOpenZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcMenuOpenZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcMenuOpenZoomOut {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcMenuOpenZoomOut {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid blue;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0,60,136,0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}
.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}
.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: #000000;
  float: right;
  z-Index: 10;
}
.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-Index: 11;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}
.ol-scale-text {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 25px;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}
.ol-scale-singlebar {
  position: relative;
  height: 10px;
  z-Index: 9;
  border: 1px solid black;
}

.ol-unsupported {
  display: none;
}
.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}
.ol-grabbing {
  cursor: grabbing;
}
.ol-grab {
  cursor: move;
  cursor: grab;
}
.ol-control {
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-control:hover {
  background-color: rgba(255,255,255,0.6);
}
.ol-zoom {
  top: .5em;
  left: .5em;
}
.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}
.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}
.ol-full-screen {
  right: .5em;
  top: .5em;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-size: 1.14em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: 1.4em;
}
.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: 1.5em;
}
.ol-touch .ol-zoom-extent {
  top: 5.5em;
}
.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: rgba(0,60,136,0.7);
}
.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}
.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}


.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
  margin: 0;
  padding: 0 .5em;
  font-size: .7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}
.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}
.ol-attribution li:not(:last-child):after {
  content: " ";
}
.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.ol-attribution ul, .ol-attribution button {
  display: inline-block;
}
.ol-attribution.ol-collapsed ul {
  display: none;
}
.ol-attribution:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em;
}
.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px;
}
.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}
.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}
.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}
.ol-overviewmap:not(.ol-collapsed) button{
  bottom: 1px;
  left: 2px;
  position: absolute;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}
.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-overviewmap-box {
  border: 2px dotted rgba(0,60,136,0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}


  /*!
  * ol-contextmenu - v3.3.1
  * https://github.com/jonataswalker/ol-contextmenu
  * Built: Wed Feb 27 2019 14:10:47 GMT-0300 (Brasilia Standard Time)
  */
.ol-ctx-menu-container {
  position: absolute;
  padding: 8px;
  background: #fff;
  color: #222;
  font-size: 13px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px;
  box-sizing: border-box;
}
.ol-ctx-menu-container div, .ol-ctx-menu-container span, .ol-ctx-menu-container a, .ol-ctx-menu-container img, .ol-ctx-menu-container ul, .ol-ctx-menu-container li {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.ol-ctx-menu-container a img {
  border: none;
}
.ol-ctx-menu-container *,
.ol-ctx-menu-container *::before,
.ol-ctx-menu-container *::after {
  box-sizing: inherit;
}
.ol-ctx-menu-container.ol-ctx-menu-hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.ol-ctx-menu-container ul {
  list-style: none;
}
.ol-ctx-menu-container li {
  position: relative;
  line-height: 20px;
  padding: 2px 5px;
}
.ol-ctx-menu-container li:not(.ol-ctx-menu-separator):hover {
  cursor: pointer;
  background-color: #333;
  color: #eee;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu .ol-ctx-menu-container {
  border: 1px solid #eee;
  padding: 8px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu:hover .ol-ctx-menu-container {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu::after {
  position: absolute;
  top: 7px;
  right: 10px;
  content: "";
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  border-right: 0.3em solid #222;
  border-top: 0.3em solid #222;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ol-ctx-menu-container li.ol-ctx-menu-submenu:hover::after {
  border-color: #eee;
}
.ol-ctx-menu-container li.ol-ctx-menu-separator {
  padding: 0;
}
.ol-ctx-menu-container li.ol-ctx-menu-separator hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.ol-ctx-menu-icon {
  text-indent: 20px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: left center;
}

.ol-ctx-menu-zoom-in {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaUlEQVQ4T72U7VHCQBCGn90GtAMuNGCswFiBWIFQgWMFxg6wArECsQKhArEBiB1Qwa1zgQn5IAYcxv13k71n3919L8KJQ07M47+BzgG9TRfZ/JBuWhS6BJFHRJICYrZGZIz3z5Ct2+B7gG6I6kt+wewdkQVwjtkAkR5mC8yu26A1oItR/cTsOweQBdgutD8G7jGm2PJ2n8oqUKIpIjd4HxTM8gvaT/F+AlmWnyWaIXKF95eNguFzTYFhNsdWu9kFgFlaFMANUH3D8wDLoLgSTSD2il8NCe2ZXQBxWDGwxmyUzzOMBZ7wy7Qb2K0wQfXjMOBuhlFpZtNty5sFaTQBuTusZdymeqs1SpYKcO9HkE3KbTd9WFijMHJQ5hBNEAYNq5Qd0dhyke0GiE4QzjqfW23mHT8Hl4DG4Lce3FPE7AtbBSdsbNqpoJLgYkRnNeUV+xwJDHTnUEkxHGbhBXUs5TjJjew/KPy94g+NRaIVRYmMXwAAAABJRU5ErkJggg==");
}

.ol-ctx-menu-container li:hover.ol-ctx-menu-zoom-in {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABc0lEQVQ4T71U21ECQRDsJgGdvQDECMQIxAjECMQILCPwzAAjECIQI0AiEDPQAPaWCBhrcKHuCUcV5f7dY3v6tUscefHIePhfwBBCF8CZqRCReRs1tQxDCH1VfQLQz4EsSY4AvIjIsgm8AhhCGKrqa9zwrqoLAKckB5HtguR1E2gBMITQU9VPAD8GICIGtl3e+xHJBwBT59xtHcsCYJZlUwA3kcGHbfDep51OZywi3/acZZm9vyJ5WR5o38uACmDunNt6ZwAkUxFZDwghDFT1jeSjiJinhVUBVNVJkiTDKO8CQA+AsbNQ7s1Ps0VVn5MkSfcCtmBoDZi1Bdx4eJ7zbBolrwPy3o9J3rWSHPs3A1BbjVKlYBaIyDgvu9LDXDU2RTZmXVW1oKyLxRD+OrkOrJLy5mVM0iaftDhuhVbsvBzMglzKUNW6IV/OOWtCM8MmVvEkmbwt83LaB19fdgOtVquUZJeknaDdobTwbOcvBzPcN/AXH1DFFWP7u9oAAAAASUVORK5CYII=");
}

.ol-ctx-menu-zoom-out {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABU0lEQVQ4T72U7VECMRRFz3sNaAdkacC1AtcKxApcKnCsQOwAK3CtQKxAqEBsANYOqCDPyTIC+8WCw5jfybn33dxEOPGSE/P4b6BzQG89RT47ZJoWhy5B5BGRZAMxWyEyxvtnyFdt8AagS1F9KQ6YvSMyB84xGyDSw2yO2XUbtAJ0MaqfmH0XAPIA2y7tj4F7jAm2uG1yWQZKNEHkBu+Dg2njWBJNEbnC+8uaIFRuWfuG2QxbbrOrUd0A1Tc8D7AIjkur7DAAsVf8MiWMZ3ZR2m02LPIMscATfjHqBnY7TFD9OAy4zTCCPG/MUKMM5O6wkXFr9dZq7FQqqHk/hDzbFa73cFONTZFDdRyiCcKg5rrSiLaXkiI6RjjrfG6VzDs+B5eAxuDXeYpmNRGzL2wZ/wof+du4GNFpBVqqz5HA4MM5VEYYDrOs+1I6Q9u/4Q8O9wN/AGgWjBVqQjjgAAAAAElFTkSuQmCC");
}

.ol-ctx-menu-container li:hover.ol-ctx-menu-zoom-out {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABYklEQVQ4T72U4VHCQBCF36tA91KAWIFYgViBWIFYgWMFYgdYgVCBWAFSgdiBFpAsFWSdxcDkQoBkhnF/ZjbfvX377ogjF4/Mw/8CVbUD4MynEJF5k2lqFapqz8yeAPRKkCXJEYAXEVnugm8BVXVgZq/FD+9mtgBwSrJfqF2QvN4FjYCq2jWzTwA/DhARh20qTdMRyQcA0xDCbZ3KCJhl2RTATaHgo+6HLMv8+xXJy+qB3l8FGoB5CKHsXcRV1b6ZvZF8FBH3NKotoJlNkiQZFONdlLtJ3rufbouZPSdJMjwIbKDQEzBrClx7eC4i33Uepmk6JnnXaOQifzMAtdGoRApugYiMI1uqKkrRWAfZo9MxM1+UZzFewl8mN4nYdVM83L7BkwbXLUrF3sfBLQDQBbDy08x8vOohXyEE71lVq9emuEk+3gZa3XYroCvwFyjP8yHJDsnxwaU08GxvS2uFhw78BbzWrxXgMbsHAAAAAElFTkSuQmCC");
}
